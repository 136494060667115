import "./JobAlert.scss";

import React from "react";
import SingleLine from "@/components/input/singleLine/SingleLine";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { SUBSCRIBE } from "@/api/subscription/Mutations";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const container = {
  visible: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Thanks = () => {
  return (
    <motion.form className="job-alert">
      <h3>We houden je op de hoogte!</h3>
      <p>
        Als er een nieuwe vacature geplaatst wordt ben je de eerste die het
        hoort!
      </p>
    </motion.form>
  );
};

const Form = ({ status, subscribe }) => {
  const [email, setEmail] = useState();

  const saveMail = () => {
    subscribe({
      variables: {
        input: {
          email,
        },
      },
    });
  };

  return (
    <motion.form
      className="job-alert"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        saveMail();
      }}
      key="form"
    >
      <motion.h3 variants={item} key="h3">
        Job alert
      </motion.h3>
      <motion.p variants={item} key="p">
        Gaat jouw hart nog niet sneller kloppen van bovenstaand aanbod? Maak
        hiernaast een job alert aan, zodat we jou per e-mail op de hoogte houden
        van onze nieuwe vacatures.
      </motion.p>
      <motion.div variants={item} key="input" className="input-holder">
        <SingleLine
          placeholder="E-mailadres"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
          autoComplete="email"
          disabled={status.loading}
          name="subscribe-email"
          className="subscribe-email"
          required
        />
      </motion.div>
      <motion.button
        disabled={status.loading}
        className="action"
        name="subscribe-button"
        variants={item}
        key="button"
      >
        <div />
        Houd mij op de hoogte!
      </motion.button>
    </motion.form>
  );
};

const JobAlert = () => {
  const [subscribe, subscriptionStatus] = useMutation(SUBSCRIBE);

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.95 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.section
      className="job-alert"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={container}
    >
      {subscriptionStatus.data ? (
        <Thanks />
      ) : (
        <Form status={subscriptionStatus} subscribe={subscribe} />
      )}
    </motion.section>
  );
};

export default JobAlert;
