import "./Clients.scss";

import React from "react";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const container = {
  visible: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const logo = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const clients = [
  {
    logo: (
      <img
        src="https://marksmen.nl/wp-content/uploads/2022/02/Capgemini-logo.png"
        alt="capgemini"
        loading="lazy"
      />
    ),
    key: "capgemini",
  },
  {
    logo: (
      <img
        src="https://marksmen.nl/wp-content/uploads/2022/02/Kwikfit-logo.png"
        alt="kwikfit"
        loading="lazy"
      />
    ),
    key: "kwikfit",
  },
  {
    logo: (
      <img
        src="https://marksmen.nl/wp-content/uploads/2022/02/Appronto-logo.png"
        alt="appronto"
        loading="lazy"
      />
    ),
    key: "appronto",
  },
  {
    logo: (
      <img
        src="https://marksmen.nl/wp-content/uploads/2022/02/Bouwatch-logo.png"
        alt="bouwatch"
        loading="lazy"
      />
    ),
    key: "bouwatch",
  },
  {
    logo: (
      <img
        src="https://marksmen.nl/wp-content/uploads/2022/02/Hanze-logo.png"
        alt="hanze"
        loading="lazy"
      />
    ),
    key: "hanze",
  },
  {
    logo: (
      <img
        src="https://marksmen.nl/wp-content/uploads/2022/02/Ilionx-logo.png"
        alt="ilionx"
        loading="lazy"
      />
    ),
    key: "ilionx",
  },
];

const Clients = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.95 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <section className="clients">
      <h2>Wie krijgen nieuwe collega’s van ons?</h2>
      <motion.div
        className="container"
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={container}
        key="clients"
      >
        {clients.map((client) => (
          <motion.div className="client" key={client.key} variants={logo}>
            {client.logo}
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

export default Clients;
