import "./LazyLoadImage.scss";

import React, { useState } from "react";

import { motion } from "framer-motion";

const LazyLoadImage = ({ blurredSrc, src, alt, onClick, ...props }) => {
  const [loaded, setLoaded] = useState();

  return (
    <motion.div
      className="lazyload"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      {...props}
    >
      <img className="blurred" alt={alt + " blurred"} src={blurredSrc} />
      <img
        loading="lazy"
        className="normal"
        style={{ opacity: loaded ? 1 : 0 }}
        alt={alt}
        src={src}
        onLoad={() => setLoaded(true)}
      />
    </motion.div>
  );
};

export default LazyLoadImage;
