import "./Page.scss";

import React, { useCallback, useContext } from "react";

import Header from "@/components/header/Header";
import Intro from "@/components/intro/Intro";
import VacancyOverview from "@/components/vacancyOverview/VacancyOverview";
import JobAlert from "@/components/jobAlert/JobAlert";
import Video from "@/components/video/Video";
import Benefits from "../components/benefits/Benefits";
import Clients from "../components/clients/Clients";
import ColleagueQuotes from "@/components/colleagueQuotes/ColleagueQuotes";
import Map from "@/components/map/Map";
import Footer from "@/components/footer/Footer";
import { useEffect } from "react";
import { AppContext } from "../App";

const Home = () => {
  const app = useContext(AppContext);

  const testFont = useCallback(async () => {
    while (!document.fonts.check("12px sofia-pro")) {
      await new Promise((resolve) => setTimeout(resolve, 10));
    }
    app.setLoading(false);
  }, [app]);

  useEffect(() => {
    app.setLoading(true);
    testFont();
  }, [app, testFont]);

  return (
    <div className="page">
      <Header />
      <Intro
        title="Werken bij Marksmen"
        excerpt="Loop jij warm voor een werkplek in een historisch pand in hartje
          Groningen, uitdagende werkzaamheden, goede voorwaarden, een geweldig
          team, een borrel op z’n tijd en tafeltennissen op niveau? Goed nieuws,
          want ons team zoekt versterking."
        image="https://storage.googleapis.com/marksmen_werkenbij/assets/homepage.jpg"
        blurImage="https://storage.googleapis.com/marksmen_werkenbij/assets/homepage-blurred.png"
        buttonText="Direct naar vacatures"
        onButtonClick={() =>
          window.scroll({
            top: window.innerHeight,
            behavior: "smooth",
          })
        }
      />
      <main>
        <VacancyOverview />
        <JobAlert />
        <Video
          src="https://storage.googleapis.com/heresta_video/video1.mov"
          controls
        />
        <Benefits />
        <ColleagueQuotes />
        <Clients />
        <Map latlng={{ lat: 53.2198958, lng: 6.5641224 }} />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
