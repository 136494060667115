import "./VacancyOverview.scss";

import React, {useState} from "react";
import {VACANCIES} from "@/api/vacancies/Queries";
import {useQuery} from "@apollo/client";

import {useInView} from "react-intersection-observer";
import {motion, useAnimation} from "framer-motion";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import JobFilter from "@/components/jobFilter/JobFilter";
import Pagination from "../pagination/Pagination";
import {JOB_CATEGORY_AND_TYPES} from "../../api/vacancies/Queries";

const container = {
    visible: {
        transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1,
        },
    },
};

const item = {
    hidden: {
        opacity: 0,
        y: 20,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const Vacancy = ({job}) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({threshold: 0.95});

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const jobslug = job.url.split("/")[job.url.split("/").length - 1];

    return (
        <motion.div
            ref={ref}
            variants={container}
            key={job.url}
            initial="hidden"
            animate={controls}
        >
            <Link to={`vacature/${jobslug}`} className="vacancy">
                <div className="information">
                    <motion.div variants={item} key="info">
                        <h4>{job.title}</h4>
                        <p>{job.excerpt}</p>
                    </motion.div>
                    <div className="tags">
                        <motion.div variants={item} className="tag" key="jobtype">
                            <span className="material-icons-round">schedule</span>
                            {job.jobType}
                        </motion.div>
                        <motion.div variants={item} className="tag" key="category">
                            <span className="material-icons-round">rocket_launch</span>
                            {job.category}
                        </motion.div>
                    </div>
                </div>
                <motion.span
                    variants={item}
                    className="material-icons-round arrow"
                    key="arrow"
                >
                    arrow_forward
                </motion.span>
            </Link>
        </motion.div>
    );
};

const VacancyOverview = () => {
    const jobs = useQuery(VACANCIES, {
        variables: {
            first: 10,
            offset: 0,
            title_Icontains: "",
            jobType: "",
            category: ""
        }
    });
    const jobCategoryAndTypes = useQuery(JOB_CATEGORY_AND_TYPES)
    useEffect(() => {
            setPagination((p) => ({
                ...p,
                totalCount: jobs.data?.jobs.totalCount
            }))
            console.log('yes')
        },
        [jobs.data?.jobs.totalCount])
    const [pagination, setPagination] = useState({
        totalCount: 0,
        offset: 0,
        pageSize: 10,
    });
    const [search, setSearch] = useState({
        title_Icontains: "",
        jobType: "",
        category: ""
    })
    return (
        <section>
            <h2>Vacatures</h2>
            <JobFilter
                onSearchChange={(payload) => {
                    setSearch((state) => ({...state, ...payload}))
                    setPagination((state) => ({...state, offset: 0}))
                    jobs.refetch({
                        ...payload,
                        first: pagination.pageSize
                    })

                }}
                categories={jobCategoryAndTypes.data?.categories}
                types={jobCategoryAndTypes.data?.jobTypes}/>
            <motion.div className="vacancies">
                {jobs.data?.jobs.edges.map((edge) => (
                    <Vacancy job={edge.node} key={edge.node.url}/>
                ))}
                {jobs.data?.jobs.edges.length < 1 && (
                    <p className="empty">
                        Er zijn op het moment nog geen vacatures beschikbaar!
                    </p>
                )}
            </motion.div>
            {!jobs.loading &&
                jobs.data?.jobs.totalCount > 10 &&
                <Pagination pagination={pagination}
                            onPaginationChange={setPagination}
                            callBack={(offset) => {
                                jobs.refetch({
                                    first: pagination.pageSize,
                                    offset: offset,
                                    ...search
                                })
                            }}

                />}
        </section>
    );
};


export default VacancyOverview;
