import "./Map.scss";

import React from "react";

import styles from "@/assets/mapStyles.json";
import { Map as GoogleMap, GoogleApiWrapper, Marker } from "google-maps-react";

const Map = ({ latlng, ...props }) => {
  return (
    <div className="map-container">
      <GoogleMap
        className="map"
        google={props.google}
        zoomControl={false}
        fullscreenControl={false}
        streetViewControl={false}
        mapTypeControl={false}
        styles={styles.style}
        initialCenter={latlng}
        zoom={14}
      >
        <Marker
          icon={{
            url: "https://storage.googleapis.com/marksmen-rep/mm-pin.svg",
          }}
        ></Marker>
      </GoogleMap>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(Map);
