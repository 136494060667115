import "./App.scss";

import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import JobDetail from "./pages/JobDetail";
import TagManager from "react-gtm-module";
import Apply from "./pages/Apply";
import SplashScreen from "@/components/splashScreen/SplashScreen";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";

const tagManagerArgs = {
  gtmId: "GTM-NCW69L3",
};

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="App">
      <AppContext.Provider value={{ loading, setLoading }}>
        <Router>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/vacature/:job_slug" exact element={<JobDetail />} />
            <Route path="/vacature/:job_slug/apply" exact element={<Apply />} />
          </Routes>
        </Router>
      </AppContext.Provider>
      <AnimatePresence>{loading && <SplashScreen />}</AnimatePresence>
    </div>
  );
}

export const AppContext = React.createContext({
  setLoading: () => {},
});

export default App;
