import "./Dropdown.scss";

const Dropdown = ({ id, options, ...props }) => {
  return (
    <label className={"dropdown"}>
      <select {...props}>
        <option value="" selected>
          {props.placeholder}
        </option>
        {options?.map((option) => (
          <option value={option}>{option}</option>
        ))}
      </select>
      <span class="material-icons-round icon">arrow_drop_down</span>
    </label>
  );
};

export default Dropdown;
