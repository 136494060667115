import { ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client";

import { onError } from "@apollo/client/link/error";

const protocol = process.env.REACT_APP_BACKEND_PROTOCOL || "http";
const host = process.env.REACT_APP_BACKEND_HOST || "localhost:8080";
const client = process.env.REACT_APP_MARKSMEN_CLIENT_ID || "marksmen";

const debug = process.env.REACT_APP_DEBUG;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, code, locations, path }) => {
      if (debug) {
        console.log(
          `[GraphQL error]: Message: ${message}, Code: ${code}, Location: ${locations}, Path: ${path}`
        );
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: `${protocol}://${client}.${host}/graphql`,
});

const link = from([errorLink, httpLink]);

const api = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default api;
