import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const container = {
  hidden: { opacity: 0, x: -50, transition: { duration: 0.4 } },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.2,
      duration: 0.4,
    },
  },
};

const Description = ({ description }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.8 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className="description"
      variants={container}
    >
      {description?.title && <h2>{description.title}</h2>}
      {description?.text && (
        <div dangerouslySetInnerHTML={{ __html: description.text }} />
      )}
    </motion.div>
  );
};

export default Description;
