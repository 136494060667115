import "./Page.scss";

import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { VACANCY } from "@/api/vacancies/Queries";
import Header from "@/components/header/Header";

import TagManager from "react-gtm-module";
import Map from "@/components/map/Map";
import Intro from "@/components/intro/Intro";
import Requirements from "./jobDetail/requirements/Requirements";
import Offer from "./jobDetail/offer/Offer";
import GrowPath from "./jobDetail/growPath/GrowPath";
import Description from "./jobDetail/description/Description";
import Video from "@/components/video/Video";
import Footer from "@/components/footer/Footer";
import ApplyButton from "@/components/applyButton/ApplyButton";

import SollicitatieProcedure from "./jobDetail/sollicitatieProcedure/SollicitatieProcedure";
import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "../App";

const JobDetail = () => {
  const { job_slug } = useParams();
  const [job, setJob] = useState();
  const app = useContext(AppContext);

  const jobStatus = useQuery(VACANCY, {
    variables: {
      url: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_COMPLETE_DOMAIN}/vacature/${job_slug}`,
    },
  });

  const sendEvent = useCallback(async (job) => {
    TagManager.dataLayer({
      dataLayer: {
        job: job.title,
        event: "pageView",
        page: "detail",
        eventType: "VIEW",
        user: {
          id: Math.random(),
        },
      },
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    app.setLoading(true);
    if (jobStatus.data) {
      setJob(jobStatus.data.job);
      sendEvent(jobStatus.data.job);
      app.setLoading(false);
    }
  }, [jobStatus.data, sendEvent, app]);

  if (!job) {
    return <div />;
  }

  return (
    <div className="page job-detail">
      <Header />
      <Intro
        title={job?.title}
        excerpt={job?.excerpt}
        image={job?.image}
        jobType={job?.jobType}
        remote={job?.remote}
        category={job?.category}
        blurImage={job?.image}
        buttonText="Ga ervoor"
        innerLink="apply"
      />
      <main>
        <section className="job-description">
          <Description
            description={job?.descriptions?.find((d) => d.key === "vacature")}
          />
          <Description
            description={job?.descriptions?.find(
              (d) => d.key === "waar-ga-je-werken"
            )}
          />
        </section>
        <Map latlng={{ lat: 53.2198958, lng: 6.5641224 }} />
        <Offer
          offer={[
            {
              icon: <span className="material-icons-round">auto_graph</span>,
              text: (
                <p>
                  <span>Doorgroeimogelijkheden</span> als <span>sourcer</span>{" "}
                  of binnen <span>digital</span> en <span>technology</span>
                </p>
              ),
            },
            {
              icon: <span className="material-icons-round">call_split</span>,
              text: (
                <p>
                  <span>Zelf</span> je <span>richting</span> bepalen
                </p>
              ),
            },
            {
              icon: <span className="material-icons-round">people</span>,
              text: (
                <p>
                  <span>Coacing</span> van een{" "}
                  <span>ervaren Talent Sourcer</span>
                </p>
              ),
            },
            {
              icon: <span className="material-icons-round">school</span>,
              text: (
                <p>
                  <span>Interne</span> en <span>externe trainingen</span>
                </p>
              ),
            },
            {
              icon: <span className="material-icons-round">laptop</span>,
              text: (
                <p>
                  Je krijgt een <span>laptop, goede koffie</span> en een{" "}
                  <span>lekkere lunch</span>
                </p>
              ),
            },
            {
              icon: <span className="material-icons-round">euro_symbol</span>,
              text: (
                <p>
                  <span>Salarisindicatie €2500</span> op basis van{" "}
                  <span>1 FTE</span>
                </p>
              ),
            },
          ]}
        />
        <section className="job-description">
          <Description
            description={job?.descriptions?.find(
              (d) => d.key === "doorgroeien"
            )}
          />
          <GrowPath
            path={[
              {
                icon: <span className="material-icons-round">person</span>,
                active: true,
                title: "Talent sourcer",
              },
              {
                icon: <span className="material-icons-round">person_add</span>,
                title: "Senior talent sourcer",
              },
              {
                icon: <span className="material-icons-round">groups</span>,
                title: "Team lead",
              },
              {
                icon: (
                  <span className="material-icons-round">auto_awesome</span>
                ),
                title: "Nog verder?",
              },
            ]}
          />
        </section>
        <Video
          src="https://storage.googleapis.com/heresta_video/video3.mov"
          controls
        />
        <section className="job-description">
          <Description
            description={job?.descriptions?.find(
              (d) => d.key === "hier-herken-je-jezelf-in"
            )}
          />
          <Requirements
            requirements={[
              {
                icon: <span className="material-icons-round">school</span>,
                text: "HBO werk en denk niveau",
              },
              {
                icon: <span className="material-icons-round">lightbulb</span>,
                text: "Creativiteit",
              },
              {
                icon: <span className="material-icons-round">hiking</span>,
                text: "Doorzettingsvermogen",
              },
              {
                icon: (
                  <span className="material-icons-round">
                    connect_without_contact
                  </span>
                ),
                text: "Communicatieve vaardigheden",
              },
              {
                icon: <span className="material-icons-round">psychology</span>,
                text: "Logisch denken",
              },
            ]}
          />
        </section>
        <SollicitatieProcedure />
      </main>
      <Footer />
      <ApplyButton applyLink="apply" />
    </div>
  );
};

export default JobDetail;
