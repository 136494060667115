import "./Requirements.scss";

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const container = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const circle = {
  hidden: {
    scale: 0,
    rotation: "30deg",
  },
  visible: {
    scale: 1,
    rotation: "0deg",
  },
};

const text = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const Requirements = ({ requirements }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.8 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.section
      className="requirements"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={container}
    >
      {requirements.map((requirement, index) => (
        <motion.div className="requirement" key={`requirement_${index}`}>
          <motion.div className="circle outter" variants={circle}>
            {requirement.icon}
          </motion.div>
          <motion.p variants={text}>{requirement.text}</motion.p>
        </motion.div>
      ))}
    </motion.section>
  );
};

export default Requirements;
