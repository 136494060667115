import { gql } from "@apollo/client";

export const SUBSCRIBE = gql`
  mutation ($input: SubscribeInput!) {
    subscribe(input: $input) {
      subscription {
        isActive
      }
    }
  }
`;
