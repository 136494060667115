import "./Pagination.scss";
import classNames from "classnames";

const Pagination = ({itemName, pagination, onPaginationChange, callBack}) => {
    const currentPage = Math.floor(pagination?.offset / pagination?.pageSize) + 1;
    const maxPages = Math.ceil(pagination?.totalCount / pagination?.pageSize);
    const range = 2;

    const getPages = () => {
        let pages = [];
        pages.push(1);
        if (currentPage - range - 1 > 1) {
            pages.push("...");
        }
        for (
            let index = currentPage - range;
            index <= currentPage + range;
            index++
        ) {
            if (index <= 1) {
                continue;
            }
            if (index >= maxPages) {
                continue;
            }
            pages.push(index);
        }
        if (currentPage + range + 1 < maxPages) {
            pages.push("...");
        }
        if (maxPages !== 1) {
            pages.push(maxPages);
        }
        return pages;
    };

    return (
        <div className="pagination-root">
            <div className="pagination-buttons">
                <button
                    disabled={pagination.offset === 0}
                    onClick={() => {
                        onPaginationChange((p) => ({
                            ...p,
                            offset: (currentPage - 2) * pagination?.pageSize,
                        }))
                        callBack((currentPage - 2) * pagination?.pageSize)
                    }
                    }
                >
                    <span className="material-icons-round">chevron_left</span>
                </button>
                {getPages().map((page, index) => (
                    <button
                        key={`page_button_${index}`}
                        className={classNames({
                            active: currentPage === page,
                            ellipsis: page === "...",
                        })}
                        disabled={page === "..."}
                        onClick={() => {
                            onPaginationChange((p) => ({
                                ...p,
                                offset: (page - 1) * pagination?.pageSize,
                            }))
                            callBack((page - 1) * pagination?.pageSize)
                        }
                        }
                    >
                        {page}
                    </button>
                ))}
                <button
                    onClick={() => {
                        onPaginationChange((p) => ({
                            ...p,
                            offset: currentPage * pagination?.pageSize,
                        }))
                        callBack(currentPage * pagination?.pageSize)
                    }
                    }
                    disabled={currentPage === maxPages}
                >
                    <span className="material-icons-round">chevron_right</span>
                </button>
            </div>
        </div>
    );
};

export default Pagination;