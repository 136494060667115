import "./Header.scss";

import React from "react";

import { motion } from "framer-motion";

const Header = () => {
  return (
    <motion.header
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        type: "spring",
        duration: 1,
        delay: 0.2,
      }}
    >
      <div className="container">
        <div className="spacing" />
        <a className="action" href="https://www.marksmen.nl/">
          <div />
          Bedrijfswebsite
        </a>
        <button className="action secondary">
          <div />
          Op de koffie?
        </button>
      </div>
    </motion.header>
  );
};

export default Header;
