import "./Offer.scss";

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const container = {
  visible: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const icon = {
  hidden: {
    scale: 0,
    rotation: "0deg",
  },
  visible: {
    scale: 1,
    rotation: "30deg",
  },
};

const text = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const Offer = ({ offer }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="offer"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={container}
    >
      <div className="content">
        <h2>Wat schuift het?</h2>
        <div className="items">
          {offer?.map((item, index) => (
            <div className="item" key={`offer_${index}`}>
              <motion.div className="icon" variants={icon}>
                {item.icon}
              </motion.div>
              <motion.div variants={text}>{item.text}</motion.div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Offer;
