import TagManager from "react-gtm-module";
import React, {useCallback, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {SHA256} from "@/util/encoding";

import SingleLine from "@/components/input/singleLine/SingleLine";
import Checkbox from "@/components/input/checkbox/Checkbox";
import Video from "@/components/video/Video";
import {useEffect} from "react";
import {useQuery, useMutation} from "@apollo/client";
import {VACANCY} from "@/api/vacancies/Queries";
import {ReactComponent as Logo} from "@/assets/logo.svg";
import {useContext} from "react";
import {AppContext} from "../App";
import {CREATE_JOB_APPLICATION} from "../api/vacancies/Queries";
import {useCookies} from "react-cookie";


const anim = {
    initial: {
        x: 50,
        opacity: 0,
    },
    animate: {
        x: 0,
        opacity: 1,
    },
    exit: {
        x: 0,
        opacity: 0,
    },
};

const Apply = () => {
    const {job_slug} = useParams();
    const [job, setJob] = useState();
    const [applied, setApplied] = useState();
    const [input, setInput] = useState({
        name: "",
        email: "",
        phone: "",
        consent: false,
    });
    const appContext = useContext(AppContext);

    const jobStatus = useQuery(VACANCY, {
        variables: {
            url: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_COMPLETE_DOMAIN}/vacature/${job_slug}`,
        },
    });
    const [jobApply] = useMutation(CREATE_JOB_APPLICATION)
    const [cookies, setCookies, removeCookies] = useCookies(['applications']);
    useEffect(() => {
        console.trace(cookies.applicationss)
    })
    const sendEvent = useCallback(async (job) => {
        TagManager.dataLayer({
            dataLayer: {
                job: job.title,
                event: "pageView",
                page: "detail",
                eventType: "VIEW",
                user: {
                    id: Math.random(),
                },
            },
        });
    }, []);

    const handleApply = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const result = await jobApply({
            variables: {
                input: {
                    fullName: input.name,
                    phoneNumber: input.phone,
                    email: input.email,
                    jobUuid: job.uuid,
                    consent: input.consent
                }
            }
        })
        // SAVE the applications done by email as a key in a cookie named applications
        let allApplies = typeof (cookies?.applications.applyed) !== 'undefined' ? cookies?.applications.applyed : []
        setCookies('applications', {
            [input.email]: [...allApplies, result.data?.createJobApplication.newApplication.uuid]
        }, {path: '/'})
        console.trace(result)
        TagManager.dataLayer({
            dataLayer: {
                job: [job_slug],
                event: "apply",
                page: "apply",
                eventType: "APPLY",
                user: {
                    id: SHA256(input.email),
                },
            },
        });

        window.location.hash = "thank-you";

        setApplied(true);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        appContext.setLoading(true);
        if (jobStatus.data) {
            setJob(jobStatus.data.job);
            sendEvent(jobStatus.data.job);
            appContext.setLoading(false);
        }
    }, [jobStatus.data, sendEvent, appContext]);

    const form = (
        <motion.form
            onSubmit={handleApply}
            key="form"
            initial={anim.initial}
            animate={anim.animate}
            exit={anim.exit}
        >
            <p>Wat tof dat je gaat voor</p>
            <h1>{job?.title}</h1>
            <p className="subtext">We hebben nog een paar details van je nodig!</p>
            <SingleLine
                required
                placeholder="Je naam"
                type="name"
                id="name"
                name="name"
                value={input?.name}
                onChange={(e) => {
                    setInput((i) => ({...i, name: e.target.value}));
                }}
            />
            <SingleLine
                required
                placeholder="Je telefoonnummer"
                type="tel"
                pattern="[0-9+]{9,13}"
                id="phone"
                name="phone"
                value={input?.phone}
                onChange={(e) => {
                    setInput((i) => ({...i, phone: e.target.value}));
                }}
            />
            <SingleLine
                required
                placeholder="Je e-mailadres"
                type="email"
                id="email"
                name="email"
                value={input?.email}
                onChange={(e) => {
                    setInput((i) => ({...i, email: e.target.value}));
                }}
            />
            <Checkbox
                title="Ik accepteer dat mijn gegevens langer dan 4 week bewaard worden"
                checked={input.consent}
                onChange={(e) => setInput((i) => ({...i, consent: e.target.checked}))}
            />
            <button id="submit" type="submit" className="action">
                Maak de stap
                <div/>
            </button>
        </motion.form>
    );

    const thankYou = (
        <motion.form
            key="thanks"
            className="thanks"
            initial={anim.initial}
            animate={anim.animate}
            exit={anim.exit}
        >
            <h2>Geweldig! De eerste stap is gezet.</h2>
            <p>Een collega neemt binnenkort contact met je op</p>
            <Link className="action" to="/">
                Terug naar vacatures
            </Link>
        </motion.form>
    );

    return (
        <div className="page apply">
            <Link to="/" className="home-link">
                <Logo/>
            </Link>
            <Video
                src="https://storage.googleapis.com/heresta_video/video3.mov"
                autoPlay={false}
                loop={false}
                forcePlay={applied}
            />
            <div className="overlay"/>
            <main>
                <AnimatePresence exitBeforeEnter>
                    {applied ? thankYou : form}
                </AnimatePresence>
            </main>
        </div>
    );
};

export default Apply;
