import "./GrowPath.scss";

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

const parent = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.12,
      duration: 0.4,
    },
  },
};

const line = {
  hidden: {
    width: 0,
  },
  visible: {
    width: "100%",
  },
};

const item = {
  hidden: {
    borderWidth: 50,
  },
  visible: {
    borderWidth: 8,
  },
};

const icon = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
  },
};

const text = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const GrowPath = ({ path }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.8 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.section
      className="growpath"
      ref={ref}
      variants={parent}
      animate={controls}
      initial="hidden"
    >
      <div className="container">
        <motion.div
          className="line"
          variants={line}
          transition={{ duration: 1 }}
        />
        {path?.map((carreerStep, index) => (
          <motion.div
            className={classNames("item", { active: carreerStep.active })}
            key={`${carreerStep.title}-${index}`}
            style={{
              left: `calc(${(100 / (path.length - 1)) * index}% - ${
                (100 / (path.length - 1)) * index
              }px)`,
            }}
          >
            <motion.div className="icon" variants={item}>
              <motion.div variants={icon}>{carreerStep.icon}</motion.div>
            </motion.div>
            <motion.span
              className="title"
              key={`path_explanation_${index}`}
              style={{
                left: `calc(${(100 / (path.length - 1)) * index}% - ${
                  (100 / (path.length - 1)) * index + 50
                }px)`,
              }}
              variants={text}
            >
              {carreerStep.title}
            </motion.span>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default GrowPath;
