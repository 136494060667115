import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import api from "./api/ApiClient";
import {ApolloProvider} from "@apollo/client";
import {CookiesProvider} from 'react-cookie';

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={api}>
            <CookiesProvider>
                <App/>
            </CookiesProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
