import "./Footer.scss";

import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <article className="cta">
          <img
            width="140"
            height="163"
            src="https://marksmen.nl/wp-content/uploads/2022/02/bart-footer2.png"
            className="attachment-large size-large"
            alt=""
            loading="lazy"
          />
          <h2>
            Heb je vragen?
            <br />
            Neem contact op met Bart!
          </h2>
          <a className="action" href="mailto:bart@marksmen.nl" target="_self">
            <div />
            Stuur mij een e-mail
          </a>
        </article>
        <article className="menu">
          <ul>
            <li>
              <p>
                <strong>Marksmen</strong>
                <br />
                Oude Boteringestraat 37-39
                <br />
                9712 GD Groningen
                <br />
                Nederland
              </p>
            </li>
            <li>
              <p>
                <strong>Contact</strong>
                <br />
                <a href="tel:31503055500">+31 (0) 50 30 555 00</a>
                <br />
                <a href="mailto:info@marksmen.nl">info@marksmen.nl</a>
              </p>
            </li>
            <li className="quicklinks">
              <ul>
                <li>
                  <strong>Snel naar</strong>
                </li>
                <li>
                  <a href="https://marksmen.nl/over-ons/" target="_self">
                    Over ons
                  </a>
                </li>
                <li>
                  <a href="https://marksmen.nl/wat-wij-doen/" target="_self">
                    Wat wij doen
                  </a>
                </li>
                <li>
                  <a href="https://marksmen.nl/onze-tools/" target="_self">
                    Onze tools
                  </a>
                </li>
                <li>
                  <a href="https://marksmen.nl/klanten/" target="_self">
                    Klanten
                  </a>
                </li>
              </ul>
            </li>
            <li className="socials">
              <ul>
                <li>
                  <strong>Volg Marksmen op</strong>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/marksmen-nederland/"
                    target="_blank"
                    className="icon"
                    rel="noreferrer"
                  >
                    <img
                      src="https://marksmen.nl/wp-content/uploads/2021/11/linkedin.svg"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://nl-nl.facebook.com/pg/marksmenNL/"
                    target="_blank"
                    className="icon"
                    rel="noreferrer"
                  >
                    <img
                      src="https://marksmen.nl/wp-content/uploads/2021/11/facebook.svg"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/marksmen_nl/"
                    target="_blank"
                    className="icon"
                    rel="noreferrer"
                  >
                    <img
                      src="https://marksmen.nl/wp-content/uploads/2021/11/instragram.svg"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </article>
        <article className="small">
          <p>
            Copyright © 2022 Marksmen Interactive B.V.
            <a
              href="https://marksmen.nl/privacystatement/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy voorwaarden
            </a>
          </p>
        </article>
      </div>
    </footer>
  );
};

export default Footer;
