import "./ColleagueQuotes.scss";

import React from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useInView } from "react-intersection-observer";

const colleagues = [
  {
    name: "Ralph Vrieling",
    role: "Talent Sourcer",
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    image: "https://marksmen.nl/wp-content/uploads/2022/02/ralph-profile.png",
  },
  {
    name: "Stephan Hilberts",
    role: "Tech & Data",
    quote:
      "Je krijgt veel vrijheid om aan projecten te werken. Er is altijd ruimte voor innovatie en er is mogelijkheid om jezelf te ontwikkelen.",
    image: "https://marksmen.nl/wp-content/uploads/2022/02/stephan-profile.png",
  },
  {
    name: "Achmad Mardjoeki",
    role: "Tech & Data",
    quote:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    image: "https://marksmen.nl/wp-content/uploads/2022/02/achmad-profile.png",
  },
  {
    name: "Aagje Wiersma",
    role: "Talent Sourcer",
    quote:
      "Ook in een parttime-functie krijg je veel verantwoordelijkheid en vrijheid om taken op te pakken die jij leuk vindt. Werken vanuit Talent staat bij Marksmen centraal.",
    image:
      "https://marksmen.nl/wp-content/uploads/2022/11/website-portret-Aagje.png",
  },
  {
    name: "Emiel van Essen",
    role: "Tech & Data",
    quote:
      "Als ontwikkelaar kun je de verantwoordelijkheid nemen voor de producten die je bouwt en heb je de ruimte om nieuwe ideeën en technieken toe te passen.",
    image: "https://marksmen.nl/wp-content/uploads/2022/02/emiel-profile.png",
  },
];

const parent = {
  visible: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
  hidden: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
};

const icon = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.6,
      type: "spring",
    },
  },
  hidden: {
    opacity: 0,
    x: 50,
    transition: {
      duration: 0.6,
      type: "spring",
    },
  },
};

const text = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.6,
      type: "spring",
    },
  },
  hidden: {
    opacity: 0,
    x: -50,
    transition: {
      duration: 0.6,
      type: "spring",
    },
  },
};

const Colleague = ({ colleague }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className="colleague"
        key={colleague.name}
        variants={parent}
        exit="hidden"
        initial="hidden"
        animate="visible"
      >
        <motion.img
          className="picture"
          src={colleague.image}
          variants={icon}
          key="picture"
        />
        <motion.div className="information" variants={text} key="information">
          <p className="text">
            <span className="quote-character">“</span>
            <span className="quote">{colleague.quote}</span>
            <span className="quote-character">”</span>
          </p>
          <p className="author">
            <span className="name"> - {colleague.name}</span>
            <br />
            <span className="role">
              <i>{colleague.role}</i>
            </span>
          </p>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const ColleagueQuotes = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.75 });

  const [selectedId, setSelectedId] = useState(0);

  const CHANGE_TIME = 3 * 1000;

  const animation = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, CHANGE_TIME));
    setSelectedId(selectedId >= colleagues.length - 1 ? 0 : selectedId + 1);
  }, [selectedId, CHANGE_TIME]);

  useEffect(() => {
    animation();
  }, [animation]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const componentAnim = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <section className="colleague-quotes full">
      <motion.div
        className="inner"
        ref={ref}
        key="colleagues"
        animate={controls}
        initial="hidden"
        variants={componentAnim}
      >
        <h2>Collega aan het woord</h2>
        <Colleague colleague={colleagues[selectedId]} controls={controls} />
      </motion.div>
    </section>
  );
};

export default ColleagueQuotes;
