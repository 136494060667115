import "./Checkbox.scss";

import React from "react";
import classNames from "classnames";

const Checkbox = ({ id, title, required, ...props }) => {
  return (
    <label
      className={classNames("checkbox")}
      onMouseDown={(e) => e.preventDefault()}
    >
      {title}
      <input {...props} type="checkbox" style={title && { marginTop: "4px" }} />
      <div className="checkmark">
        <div className="checked" />
      </div>
    </label>
  );
};

export default Checkbox;
