import "./SollicitatieProcedure.scss";

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const proces = [
  {
    image:
      "https://storage.googleapis.com/marksmen_werkenbij/assets/homepage.jpg",
    text: "Solliciteren",
  },
  {
    image:
      "https://storage.googleapis.com/marksmen_werkenbij/assets/homepage.jpg",
    text: "Koffie drinken",
  },
  {
    image:
      "https://storage.googleapis.com/marksmen_werkenbij/assets/homepage.jpg",
    text: "Nog een keer koffie drinken",
  },
  {
    image:
      "https://storage.googleapis.com/marksmen_werkenbij/assets/homepage.jpg",
    text: "Tekenen",
  },
  {
    image:
      "https://storage.googleapis.com/marksmen_werkenbij/assets/homepage.jpg",
    text: "Aan de slag",
  },
];

const container = {
  visible: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const itemAnimation = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const SollicitatieProcedure = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.75 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <section className="sollicitatie-procedure">
      <h2>Wat nu?</h2>
      <motion.div
        className="container"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={container}
      >
        <div className="item-scroll">
          {proces?.map((item, index) => (
            <motion.div
              className="item"
              variants={itemAnimation}
              key={`sollicitatie_procedure_${index}`}
            >
              <motion.div className="number">{index + 1}</motion.div>
              <div className="details">
                <motion.p>{item.text}</motion.p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default SollicitatieProcedure;
