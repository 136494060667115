import {gql} from "@apollo/client";

export const VACANCIES = gql`
  query($first:Int, $offset:Int, $title_Icontains:String, $jobType:String, $category:String){
      jobs(first:$first, offset:$offset, title_Icontains:$title_Icontains, jobType:$jobType, category:$category) {
        totalCount
        edges {
          node {
            title
            excerpt
            url
            jobType
            category
          }
        }
      }
  }
`;

export const VACANCY = gql`
  query ($url: String!) {
    job(url: $url) {
      uuid
      title
      excerpt
      url
      jobType
      category
      image
      jobType
      remote
      salary {
        min
        max
        valuta
      }
      descriptions {
        title
        key
        text
      }
    }
  }
`;
export const JOB_CATEGORY_AND_TYPES = gql`
  {
      jobTypes,
      categories
  }
`;

export const CREATE_JOB_APPLICATION = gql`
    mutation ($input: CreateJobApplicationInput!) {
      createJobApplication(input: $input) {
        newApplication {
          uuid
        }
      }
    }
`;