import "./Video.scss";

import React, { useState } from "react";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import classNames from "classnames";

const Video = ({ controls, forcePlay, src, autoPlay = true, loop = true }) => {
  const [playing, setPlaying] = useState(autoPlay);
  const [hovering, setHovering] = useState(false);

  return (
    <div
      className="video"
      onMouseEnter={() => {
        setHovering(true);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
    >
      <ReactPlayer
        url={src}
        width="100%"
        height="100%"
        playing={forcePlay || playing}
        loop={loop}
      />
      {controls && (
        <div
          className={classNames("controls", { hovering: !playing || hovering })}
        >
          <motion.div className="overlay">
            <button
              onClick={() => {
                setPlaying(!playing);
              }}
            >
              <span className="material-icons-round">
                {playing ? "pause" : "play_arrow"}
              </span>
            </button>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default Video;
