import "./SplashScreen.scss";

import React from "react";
import { ReactComponent as MM } from "@/assets/mm.svg";
import { motion } from "framer-motion";

const anim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const SplashScreen = () => {
  return (
    <motion.div
      className="splash"
      hidden={anim.hidden}
      exit={anim.hidden}
      animate={anim.visible}
      initial={anim.hidden}
    >
      <MM />
    </motion.div>
  );
};

export default SplashScreen;
