import "./Benefits.scss";

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

const container = {
  visible: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const icon = {
  hidden: {
    scale: 0,
    rotation: "0deg",
  },
  visible: {
    scale: 1,
    rotation: "30deg",
  },
};

const text = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const items = [
  {
    icon: "business",
    title: "‘Werkplekkie hoor!’",
    text: "Je ziet de Martinitoren en ruikt de Vismarkt. Nou ja, bijna dan. Feit is wel dat Marksmen gesitueerd is in een prachtig pand aan de befaamde Oude Boteringestraat. Met leuke, betrokken collega’s die van alles met zich meebrengen. Van koffiegek tot vaderschap, van whiskey-adept tot tafeltennis-skills en van Harry Potter-fan tot zeilliefhebber.",
  },
  {
    icon: "people",
    title: "Fijne werksfeer",
    text: "Op kantoor weet zich dat te vertalen in gezelligheid, betrokkenheid, goede koffie en elke werkdag een verse lunch. Buiten de deur verpakken we dat in (vrijdagmiddag)borrels, teambuilding-momenten en een jaarlijkse stedentrip. Sterk in tafeltennis? We genieten vooral als collega Ralph eens verliest.",
  },
  {
    icon: "home_work",
    title: "Hybride werken",
    text: "Ja, dat is helemaal van nu hè? Wij zijn pro thuiswerken en hybride werken. Daar hoort natuurlijk een spiksplinternieuwe laptop bij, zodat je altijd en overal kunt inloggen en werken. Thuis, op kantoor, bij de klant of - wat ons betreft - op Schiermonnikoog (wel even uitloggen als je op vakantie gaat).",
  },
  {
    icon: "insights",
    title: "Groeipad",
    text: "Een start-up met volwassen trekjes, zo zou je ons inmiddels kunnen zien. We ontwikkelen, groeien en leren. Iets wat we ook binnen onze cultuur en dus onze collega’s terugzien. Jij bepaalt je eigen (groeipad, wat wordt versterkt door een persoonlijk ontwikkelplan, diverse doorgroeimogelijkheden en zowel interne als externe trainingen.",
  },
];

const Benefit = ({ item }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.95 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="item"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={container}
    >
      <motion.div variants={icon}>
        <span className="material-icons-round">{item.icon}</span>
      </motion.div>
      <motion.div variants={text}>
        <motion.h3>{item.title}</motion.h3>
        <motion.p>{item.text}</motion.p>
      </motion.div>
    </motion.div>
  );
};

const Benefits = () => {
  return (
    <section>
      <motion.div className="benefits">
        <h2>Wat wij te bieden hebben</h2>
        {items.map((item, index) => (
          <Benefit item={item} key={`benefit-${index}`} />
        ))}
      </motion.div>
    </section>
  );
};

export default Benefits;
