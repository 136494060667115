import "./ApplyButton.scss";

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useCallback } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const ApplyButton = ({ applyLink }) => {
  const [isSticky, setSticky] = useState();

  const handleScroll = useCallback(() => {
    if (
      document.documentElement.scrollTop >
      document.documentElement.clientHeight - 200
    ) {
      if (!isSticky) {
        setSticky(true);
      }
    } else {
      setSticky(false);
    }
  }, [isSticky]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, [handleScroll]);

  return (
    <motion.div>
      <Link
        className={classNames("apply-button action", { sticky: isSticky })}
        to={applyLink}
      >
        <div />
        Ga ervoor
      </Link>
    </motion.div>
  );
};

export default ApplyButton;
