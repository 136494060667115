import "./JobFilter.scss";

import SingleLine from "@/components/input/singleLine/SingleLine";
import Dropdown from "../input/dropdown/Dropdown";
import {useState} from "react";

const JobFilter = ({onSearchChange, categories = [], types = []}) => {
    const [payload, setPayload] = useState({
        title_Icontains: "",
        jobType: "",
        category: ""
    })
    const triggerSearch = (e) => {
        setPayload((state) => ({...state, [e.target.name]: e.target.value}))
        onSearchChange({...payload, [e.target.name]: e.target.value})
    }
    return (
        <div className="jobfilter">
            <SingleLine search value={payload.title_Icontains} name={'title_Icontains'} onBlur={triggerSearch}
                        onChange={triggerSearch}/>
            <Dropdown placeholder="Selecteer cetagorie" value={payload.category} name={'category'}
                      onBlur={triggerSearch} options={categories}
                      onChange={triggerSearch}/>
            <Dropdown placeholder="Selecteer type" value={payload.jobType} name={'jobType'} onBlur={triggerSearch}
                      options={types}
                      onChange={triggerSearch}/>
        </div>
    );
};

export default JobFilter;
