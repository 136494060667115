import "./Intro.scss";
import LazyLoadImage from "@/components/lazyLoadImage/LazyLoadImage";
import { ReactComponent as Logo } from "@/assets/logo.svg";
import { Link } from "react-router-dom";

import React from "react";
import { motion } from "framer-motion";

const parentAnim = {
  visible: {
    transition: {
      delayChildren: 0.6,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.6,
      type: "spring",
    },
  },
  hidden: {
    opacity: 0,
    x: -50,
    transition: {
      duration: 0.6,
      type: "spring",
    },
  },
};

const Intro = ({
  title,
  image,
  blurImage,
  excerpt,
  buttonText,
  onButtonClick,
  jobType,
  remote,
  category,
  innerLink,
}) => {
  return (
    <div className="intro">
      <LazyLoadImage src={image} blurredSrc={blurImage || image} />
      <div className="image-overlay" />
      <Link to="/" className="home-link">
        <Logo />
      </Link>
      <motion.div
        className="content"
        variants={parentAnim}
        initial="hidden"
        animate="visible"
        key="info"
      >
        <motion.h1 key="h1" variants={item}>
          {title}
        </motion.h1>
        <motion.p key="p" variants={item}>
          {excerpt}
        </motion.p>
        <motion.div key="tags" className="tags" variants={item}>
          {jobType && (
            <motion.div>
              <span className="material-icons-round">schedule</span>
              {jobType}
            </motion.div>
          )}
          {category && (
            <motion.div>
              <span className="material-icons-round">rocket_launch</span>
              {category}
            </motion.div>
          )}
          {remote && (
            <motion.div>
              <span className="material-icons-round">home</span>Thuiswerken
              mogelijk
            </motion.div>
          )}
        </motion.div>
        {onButtonClick && (
          <motion.button
            className="action"
            key="button"
            variants={item}
            onClick={() => onButtonClick()}
          >
            <div />
            {buttonText}
          </motion.button>
        )}
        {innerLink && (
          <motion.div key="button" variants={item}>
            <Link className="action" to={innerLink}>
              <div />
              {buttonText}
            </Link>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default Intro;
