import "./SingleLine.scss";

import React from "react";
import classNames from "classnames";

const SingleLine = ({ id, title, tooltip, error, auth, search, ...props }) => {
  return (
    <label className={classNames("singleline", { error, auth, search })}>
      {title}
      <input
        {...props}
        placeholder={
          search
            ? "Search..."
            : props.required
            ? props.placeholder + "*"
            : props.placeholder
        }
        style={title && { marginTop: "4px" }}
      />
      {search && (
        <span className="material-icons-round search-icon">search</span>
      )}
      {error && <p>{error}</p>}
    </label>
  );
};

export default SingleLine;
